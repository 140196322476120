<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="企业名称:" prop="compName">
            <el-input v-model="searchModel.compName"></el-input>
          </el-form-item>
          <el-form-item label="签订日期:">
            <el-date-picker
              v-model="searchModel.signDateStart"
              type="date"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd"
            />
            --
            <el-date-picker
              v-model="searchModel.signDateEnd"
              type="date"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="客户名称:" prop="relaId">
            <VirtualSelect
              v-model="searchModel.relaId"
              :option="{label:'firm',value:'relaId'}"
              :data="relaList"
            ></VirtualSelect>
          </el-form-item>
          <el-form-item label="合同类型:" prop="contractClass">
            <el-select v-model="searchModel.contractClass" clearable>
              <el-option v-for="item in contraTypeList"
                         :key="item.dictValue"
                         :label="item.dictName"
                         :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同状态:" prop="contractStatus">
            <el-select v-model="searchModel.contractStatus" clearable>
              <el-option v-for="item in contraStatusList"
                         :key="item.dictValue"
                         :label="item.dictName"
                         :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              v-auth="'商砼合同_新增'"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;addRow()"
            >新增
            </el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              v-auth="'商砼合同_导出'"
              type="default"
              icon="el-icon-upload2"
              @click="
                exportTabData();
              "
            >导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="contractManConcrete"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :align="allAlign"
          :data="tableData">
          <vxe-table-column type="seq" title="序号" width="60"/>
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column field="companyName" title="企业名称" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="contractNum" title="合同编号" min-width="60" show-overflow="title"
                            show-header-overflow="title"  />
          <vxe-table-column field="projName" title="工程名称" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="firm" title="客户名称" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="pastyPrice" title="合同总价(万元)" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="pastyProductionMount" title="合同数量(吨)" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="contractClass" title="合同类型" min-width="60" show-overflow="title"
                            show-header-overflow="title"  :formatter="  ({cellValue, row, column }) => {
                              return cellValue?(contraTypeList.filter(item=>(item.dictValue == cellValue))[0].dictName):''
                            }"/>
          <vxe-table-column field="operStaffName" title="业务员" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="signDate" title="签订日期" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="contractStatus" title="合同状态" min-width="60" show-overflow="title"
                            show-header-overflow="title" :formatter="  ({cellValue, row, column }) => {
                              return cellValue?(contraStatusList.filter(item=>(item.dictValue == cellValue))[0].dictName):''
                            }" />
          <vxe-table-column field="remark" title="备注" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column field="voucherNowRelativeUrls" title="附件" min-width="60" show-overflow="title"
                            show-header-overflow="title" />
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)"
                         v-auth="'商砼合同_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)"
                         v-auth="'商砼合同_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog :title="dialogEditTitle"
                 :visible.sync="dialogEdit"
                 :v-if="dialogEdit"
                 :append-to-body="true"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
                 :destroy-on-close="false"
                 v-dialogDrag
                 width="860px">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="200px"
                 style="margin-top: 20px">
          <el-row>
            <el-form-item label="合同编号:" props="contractNum">
              <el-input
                v-model="contracEditModel.contractNum"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="合同名称:" props="contractName">
              <el-input
                v-model="contracEditModel.contractName"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="合同类型:" props="contractClass">
              <el-select v-model="contracEditModel.contractClass" clearable>
                <el-option v-for="item in contraTypeList"
                           :key="item.dictValue"
                           :label="item.dictName"
                           :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="工程名称:" props="projNum">
              <el-autocomplete
                class="inline-input"
                v-model="contracEditModel.projName"
                ref="autocomplete"
                hide-loading
                :fetch-suggestions="queryProjSearch"
                placeholder="请输入工程名称"
                @select="handleProjSelect"
                :debounce="10"
                :title="contracEditModel.projName"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item >
              <span id="none_proj">没有找到工程？</span>
              <el-button
                type="warn"
                icon="el-icon-tickets"
                @click="projEditModel = undefined;dialogProjEdit = true;"
              >添加工程
              </el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="客户名称:" props="supeFirm">
              <el-input
                disabled
                v-model="contracEditModel.firm"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="工程地址:" props="address">
              <el-input
                disabled
                v-model="contracEditModel.address"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="联系人:" props="linkName">
              <el-input
                disabled
                v-model="contracEditModel.linkName"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="联系电话:" props="linkPhone">
              <el-input
                disabled
                v-model="contracEditModel.linkPhone"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="合同总价(万元):">
              <el-input
                v-model="contracEditModel.pastyPrice"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="合同数量(方):" props="needMount">
              <el-input
                v-model="contracEditModel.needMount"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="定价方式:" props="priceType" >
              <el-select v-model="contracEditModel.priceType" clearable   style="width: 180px">
                <el-option v-for="item in priceTypeList"
                           :key="item.dictValue"
                           :label="item.dictName"
                           :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结算方式:" prop="paymentMethod">
              <el-input
                v-model="contracEditModel.paymentMethod"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="合同状态:" prop="contractStatus">
              <el-select v-model="contracEditModel.contractStatus" clearable  style="width: 180px">
                <el-option v-for="item in contraStatusList"
                           :key="item.dictValue"
                           :label="item.dictName"
                           :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务员:" props="operStaffName">
              <el-input
                v-model="contracEditModel.operStaffName"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="签订日期:">
              <el-date-picker
                v-model="contracEditModel.signDate"
                type="date"
                placeholder="选择日期时间"
                style="width: 180px"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item label="建设规模:" props="busLicPlate">
              <el-input
                v-model="contracEditModel.busLicPlate"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="备注:" props="concrete">
              <el-input
                v-model="contracEditModel.concrete"
                style="width: 420px"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="附件:" props="concrete">
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="1"
                :http-request="uploadFile"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              <!--              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogBusEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitContractEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
      <edit-proj :visible.sync="dialogProjEdit" :edit-data.sync="projEditModel" @refresh="gainProjList" :rela-items="relaItems" :region-options="regionOptions" :region-props="regionProps"/>
    </div>
  </page-container>
</template>

<script>
import editProj from '@/views/priceManage/components/editProj'
import { getRelaOne } from '@/apis/priceManage/projectMan'
import { getContractPage, addModContract, exportExcelContract, delContract, getRelaList, gainContractStatus, gainContractType, getProjList0, gainpriceType, gainContractOne } from '@/apis/saleManage/contractMan'
import { gainRegi } from '@/apis/common'
import XEUtils from 'xe-utils'
import { exportToxlsx } from '@/utils/tools'
import { mapState } from 'vuex'
import http from '@/utils/http'
export default {
  name: 'contractMan',
  data () {
    return {
      fileList: [],
      http: http,
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      regionArr: [],
      editMode: 'add',
      dialogProjEdit: false,
      relaTypeOptions: {
        1: '建设',
        2: '设计',
        3: '施工',
        4: '监理',
        5: '质监'
      },
      regionOptions: [],
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then((res) => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map((val) => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    }))
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      contracEditModel: {
        companyName: '',
        contractId: '',
        contractClass: '',
        contractName: '',
        contractNum: '',
        contractStatus: '',
        needMount: 0,
        operStaffName: '',
        pastyPrice: 0,
        paymentMethod: '',
        priceType: 0,
        projId: 0,
        relaId: 0,
        remark: '',
        salesStaffName: '',
        signDate: '',
        squareRebate: 0,
        type: 1,
        uploadFlag: 0,
        voucherNowRelativeUrls: '',
        address: '',
        linkName: '',
        linkPhone: '',
        supeFirm: ''
      },
      rules: {

      },
      dialogEditTitle: '新增车辆',
      dialogEdit: false,
      searchModel: {
        signDateEnd: '',
        signDateStart: '',
        relaId: '',
        contractStatus: '',
        contractClass: '',
        companyName: ''
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      relaList: [],
      contraTypeList: [],
      contraStatusList: [],
      relaItems: [],
      priceTypeList: [],
      projEditModel: {},
      remoteLoading: false,
      calcTimer: null
    }
  },
  computed: {
    ...mapState('account', ['loginInfo', 'userInfo'])
  },
  methods: {
    remoteMethod (query) {
      this.remoteLoading = true
      if (query.length > 0) {
        getRelaList({ firm: query }).then((res) => {
          this.relaList = res.data
          return res.data
        })
      }
    },
    uploadFile (content) {
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.contracEditModel.voucherNowRelativeUrls = res.data.nowRelatUrl
        console.log(555, this.contracEditModel.voucherNowRelativeUrls)
        // this.loading = false
      })
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    queryProjSearch (queryString, cb) {
      if (queryString.length > 0) {
        getProjList0({ projName: queryString }).then(res => {
          const projItems = []
          res.data.forEach(item => {
            const temp = item
            temp.value = item.projName
            projItems.push(temp)
          })
          this.projectItems = projItems

          cb(projItems.splice(0, (projItems.length > 30 ? 30 : projItems.length)))
        })
      }
    },
    handleProjSelect (item) {
      this.contracEditModel = XEUtils.assign(this.contracEditModel, {
        projId: item.projId,
        contractName: item.projName,
        address: XEUtils.isString(item.address) ? item.address : '',
        linkName: XEUtils.isString(item.linkName) ? item.linkName : '',
        linkPhone: XEUtils.isString(item.linkPhone) ? item.linkPhone : '',
        firm: XEUtils.isString(item.firm) ? item.firm : ''
      })
    },
    gainProjList () {
      // 关联工程
      getProjList0({
        projName: ''
      }).then(res => {
        const projItems = []
        res.data.forEach(item => {
          const temp = item
          temp.value = item.projName
          projItems.push(temp)
        })
        this.projectItems = projItems
      })
    },
    refreshRelaCallback () {
      getRelaOne({
      }).then(res => {
        if (res.code == 200) {
          const relaItems = []
          res.data.forEach(item => {
            const temp = item
            if (item.uniteCredit) {
              temp.value = item.uniteCredit
              relaItems.push(temp)
            }
          })
          this.relaItems = relaItems
        }
      })
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    exportTabData () {
      exportExcelContract({
        signDateEnd: '',
        signDateStart: '',
        relaId: '',
        contractStatus: '',
        contractClass: '',
        companyName: ''
      }).then(res => {
        exportToxlsx(res, '合同列表')
      })
    },
    refreshData () {
      this.loading = true
      getContractPage({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalPages
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    addRow () {
      this.editMode = 'add'
      this.dialogEditTitle = '新增合同'
      this.contracEditModel = {
        contractId: '',
        contractClass: '',
        contractName: '',
        contractNum: '',
        contractStatus: '',
        needMount: 0,
        operStaffName: '',
        pastyPrice: 0,
        paymentMethod: '',
        priceType: 0,
        projId: 0,
        relaId: 0,
        remark: '',
        salesStaffName: '',
        signDate: '',
        squareRebate: 0,
        type: 1,
        uploadFlag: 0,
        voucherNowRelativeUrls: '',
        address: '',
        linkName: '',
        linkPhone: '',
        supeFirm: ''
      }
    },
    editRow (row) {
      gainContractOne({ id: row.contactsId }).then(res => {
        //  this.modifyDetailRow = row
        this.dialogEditTitle = '编辑合同'
        const rowData = {}
        XEUtils.objectEach(this.contracEditModel, (item, key) => {
          rowData[key] = row[key] ? row[key].toString() : ''
        })
        this.contracEditModel = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该合同信息?', '删除合同', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delContract({
          ids: [row.contractId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else { this.$message.error(res.msg) }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
    },
    onSubmitContractEdit () {
      /* this.$refs.addForm.validate((valid) => {
        if (valid) { */
      addModContract({ ...this.contracEditModel }).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功！')
          this.dialogEdit = false
          this.refreshData()
        } else { this.$message.error(res.msg) }
      })
      /*   }
      }) */
    }
  },
  mounted () {
    // 定价方式
    gainpriceType().then(res => {
      this.priceTypeList = res.data
    })
    // 关联客户名称
    getRelaList().then(res => {
      this.relaList = res.data
    })
    // 合同类型
    gainContractType().then((res) => {
      this.contraTypeList = res.data
    })
    // 合同状态
    gainContractStatus().then((res) => {
      this.contraStatusList = res.data
    })
    // 关联工程
    // this.gainProjList()
    // 所属区域
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then((res) => {
      if (res.code == 200) {
        const regionOptions = res.data.map((val) => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map((valCity) => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
        this.regionOptions = regionOptions
      }
    })
      .catch((val) => {
        console.log(val)
      })
    this.refreshData()
  },
  components: {
    editProj
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
#none_proj{
  color:#e6a23c;
}

</style>
