<template>
  <el-dialog  v-bind="{title:editData?'编辑工程':'新建工程',width:'860px'}"
              @open="handlerOpen"
              @closed="handlerClose"
              :visible.sync="visibleDialog">

    <el-form :inline="true" ref="form" size="mini" label-position="right" label-width="200px"
             :rules="rules"
             :model="submitForm"
             style="margin-top: 20px">
      <el-row>
        <el-form-item label="上传电子证照">
          <el-upload
            ref="uploadRef"
            :action="url"
            :on-change="uploadSuccess"
            :http-request="uploadPicture"
            :show-file-list="false">
            <el-button size="small" type="primary">上传电子证照</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item v-if="submitForm.imgURL||submitForm.imgURL!=''">
          <img :src="getMediaUrl(submitForm.imgURL)" class="avatar show_imgUrl" >
          <!--            <el-link class="ml-10" @dblclick.native="downloadMedia" target="_blank" type="info" title="双击预览附件">
            <i class="el-icon-link"></i>
            已传附件
          </el-link>-->
          <el-icon class="delete-pdf el-icon-delete ml-5" @click.native="submitForm.imgURL =''"></el-icon>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="工程名称:" prop="projName">
          <el-autocomplete
            class="inline-input"
            v-model.trim="submitForm.projName"
            :fetch-suggestions="queryProjSearch"
            placeholder="请输入内容"
            @select="handleProjSelect"
            :debounce="10"
            @change = "$forceUpdate()"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="建设地址:" prop="address">
          <el-input
            v-model="submitForm.address"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="工程项目编号:" prop="projNum">
          <el-input
            v-model="submitForm.projNum"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="施工许可证电子证照编号:" prop="consLiceNum">
          <el-input
            v-model="submitForm.consLiceNum"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="项目分类:" prop="projType">
          <el-select
            v-model="submitForm.projType"
            placeholder="请选择项目分类"
            filterable
            clearable
            style="width: 180px"
          >
            <el-option label="普通" value="1"></el-option>
            <el-option label="重点" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目地址:" prop="address">
          <el-input
            v-model="submitForm.address"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="项目负责人:" prop="lastLinkName">
          <el-input
            v-model="submitForm.lastLinkName"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="项目负责人联系方式:" prop="lastLinkPhone">
          <el-input
            v-model="submitForm.lastLinkPhone"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="计划开工日期:">
          <el-date-picker
            v-model="submitForm.initDat"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="计划竣工时间:">
          <el-date-picker
            v-model="submitForm.complDat"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="合同价格（万元）:" prop="price">
          <el-input
            v-model="submitForm.price"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="总面积（平方米）:" prop="coveArea">
          <el-input
            v-model="submitForm.coveArea"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="合计地上面积（平方米）:" prop="aboveArea">
          <el-input
            v-model="submitForm.aboveArea"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="合计地下面积（平方米）:" prop="underArea">
          <el-input
            v-model="submitForm.underArea"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="管理属地:">
          <el-cascader
            ref="editCascader"
            v-model="regionArr"
            clearable
            placeholder="请选择区域"
            :options="regionOptions"
            :props="regionProps"
            @change="handleItemChange"
            style="width: 180px"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="建设规模:" prop="coveArea">
          <el-input
            v-model="submitForm.coveArea"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="预计混凝土用量（立方）:" prop="concrete">
          <el-input
            v-model="submitForm.concrete"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="预计砂浆用量（吨）:" prop="mortar">
          <el-input
            v-model="submitForm.mortar"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <p class="fs-16 bold mb-15">参加单位信息</p>
      </el-row>
      <el-row>
        <el-table border :data="submitForm.relaList" style="width: 100%" :row-class-name="tableRowClassName" @row-click = "onRowClick">
          <el-table-column  prop="relaType" label="承担角色">
            <template scope="scope">
              <el-select v-model="scope.row[scope.column.property]" clearable value-key="index">
                <template v-for="(value, key) in relaTypeOptions">
                  <el-option
                    v-if="typeof(scope.row[scope.column.property]) =='string'"
                    :key="key"
                    :label="value"
                    :value="''+key">
                  </el-option>
                  <el-option
                    v-else-if="typeof(scope.row[scope.column.property]) =='number'"
                    :key="key"
                    :label="value"
                    :value="((''+key).trim() == '')?'':parseInt(key)">
                  </el-option>
                  <el-option
                    v-else
                    :key="key"
                    :label="value"
                    :value="key">
                  </el-option>
                </template>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="uniteCredit" label="企业统一社会信用代码">
            <template scope="scope">
              <el-autocomplete
                v-model="scope.row.uniteCredit"
                :fetch-suggestions="queryRelaSearch"
                @select="handleRelaSelect"
              ></el-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="firm" label="单位名称" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.firm"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="linkName" label="负责人姓名" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.linkName"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="linkCard" label="项目负责人证件号码" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.linkCard"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right"  label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-minus"
                circle
                @click.native.prevent="removeUnitRow(scope.$index, submitForm.relaList)"
                size="mini"
                title="删除"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <div style="width: 100%;text-align: center;margin-top:10px;margin-bottom: 10px;"> <el-button
          type="primary"
          icon="el-icon-plus"
          circle
          @click="addUnitRow()"
          size="mini"
          title="添加"
        /></div>
      </el-row>
      <el-row>
        <p class="fs-16 bold mb-15">单体项目信息</p>
      </el-row>
      <el-row>
        <el-table border :data="submitForm.projMonList" style="width: 100%" >
          <el-table-column  prop="monName" label="单体名称">
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.monName"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="firm" label="单体参数" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.monParam"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="linkName" label="单体其他参数" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.monOther"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right"  label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-minus"
                circle
                @click.native.prevent="removeMonRow(scope.$index, submitForm.projMonList)"
                size="mini"
                title="删除"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <div style="width: 100%;text-align: center;margin-top:10px;margin-bottom: 10px;"> <el-button
          type="primary"
          icon="el-icon-plus"
          circle
          @click="insertMonEvent()"
          size="mini"
          title="添加"
        /></div>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog = false" size="mini">取消</el-button>
      <el-button type="primary" @click="submit" size="mini">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/http'
import { addProj, modProj, gainProjCallback } from '@/apis/priceManage/projectMan'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    relaItems: {
      type: Array,
      default () {
        return []
      }
    },
    regionOptions: {
      type: Array,
      default () {
        return []
      }
    },
    regionProps: {
      type: Object
    },
    regionArr: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      projectItems: [],
      http: http,
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      loading: false,
      relaTypeOptions: {
        1: '建设',
        2: '设计',
        3: '施工',
        4: '监理',
        5: '质监'
      /*  10: '企业下客户',
        20: '企业下供应商',
        30: '机构下质检',
        40: '保险公司',
        50: '设备供应商' */
      },
      submitForm: {
        projName: '',
        projNum: '',
        coveArea: 0,
        aboveArea: 0,
        address: '',
        builFirm: '',
        complDat: '',
        concrete: 0,
        consLiceNum: '',
        imgURL: '',
        initDat: '',
        lastConsFirm: '',
        lastLinkName: '',
        lastLinkPhone: '',
        opertime: '',
        projId: '',
        on: true,
        price: 0,
        projType: '',
        regiCountrId: '',
        regiCityId: '',
        regiProvinId: '',
        countrName: '',
        cityName: '',
        provinName: '',
        relaType: '',
        coverArea: 0,
        relaList: [
        ],
        projMonList: [
        ]
      },
      rules: {
        projName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    addUnitRow () {
      this.submitForm.relaList.push({
        linkName: '',
        linkPhone: '',
        linkCard: '',
        firm: '',
        relaType: '',
        uniteCredit: '',
        accoId: ''
      })
    },
    insertMonEvent () {
      this.submitForm.projMonList.push({
        monName: '',
        monOther: '',
        monParam: '',
        projId: '',
        projMonId: ''
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      row.row_index = rowIndex
    },
    onRowClick (row, event, column) {
      this.currentRelaIndex = row.row_index
    },
    handleItemChange (val) {
    },
    queryProjSearch (queryString, cb) {
      const projectItems = this.projectItems
      const results = queryString ? projectItems.filter(item => (item.prjname.indexOf(queryString) > -1)) : projectItems
      // 调用 callback 返回建议列表的数据
      cb(results.splice(0, (results.length > 30 ? 30 : results.length)))
    },
    handleProjSelect (item) {
      this.submitForm.address = item.address
      this.submitForm.projNum = item.prjnum
      this.submitForm.coveArea = item.allarea
      this.submitForm.coveArea = item.allarea
      this.submitForm.regiCountrId = item.regiCountrId
      this.submitForm.regiCityId = item.regiCityId
      this.submitForm.regiProvinId = item.regiProvinId
    },
    queryRelaSearch (queryString, cb) {
      const relaItems = this.relaItems
      const results = queryString ? relaItems.filter(item => (item.uniteCredit.indexOf(queryString) > -1)) : relaItems
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleRelaSelect (rela) {
      const curIndex = this.currentRelaIndex
      this.submitForm.relaList[curIndex].relaType = rela.relaType.toString()
      this.submitForm.relaList[curIndex].firm = rela.firm
      this.submitForm.relaList[curIndex].linkName = rela.linkName
      this.submitForm.relaList[curIndex].linkPhone = rela.linkPhone
    },
    // 上传图片
    uploadPicture (content) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.submitForm.imgURL = res.data.nowRelatUrl
        // this.loading = false
      })
    },

    uploadSuccess () {
      console.log('uploadSuccess')
      this.reload = true
      this.$nextTick(() => {
        this.reload = false
      })
    },
    removeUnitRow (index, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
      })
    },
    removeMonRow (index, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
      })
    },
    refreshProjCallback () {
      gainProjCallback({
        prjname: '',
        prjnum: ''
      }).then(res => {
        if (res.code == 200) {
          const projItems = []
          res.data.forEach(item => {
            const temp = item
            temp.value = item.prjname
            projItems.push(temp)
          })
          this.projectItems = projItems
        }
      })
    },
    submit () {
      const pathLabels = this.$refs.editCascader.getCheckedNodes()[0] ? this.$refs.editCascader.getCheckedNodes()[0].pathLabels : []
      const param = {
        projName: this.submitForm.projName,
        projNum: this.submitForm.projNum,
        coveArea: this.submitForm.coveArea,
        aboveArea: this.submitForm.aboveArea,
        address: this.submitForm.address,
        complDat: this.submitForm.complDat,
        mortar: this.submitForm.mortar,
        consLiceNum: this.submitForm.consLiceNum,
        imgURL: this.submitForm.imgURL,
        initDat: this.submitForm.initDat,
        lastLinkName: this.submitForm.lastLinkName,
        lastLinkPhone: this.submitForm.lastLinkPhone,
        opertime: this.submitForm.opertime,
        projId: this.submitForm.projId,
        on: true,
        price: this.submitForm.price,
        regiCountrId: this.regionArr[2] ? this.regionArr[2] : undefined,
        regiCityId: this.regionArr[1] ? this.regionArr[1] : undefined,
        regiProvinId: this.regionArr[0] ? this.regionArr[0] : undefined,
        countrName: pathLabels[2] ? pathLabels[2] : undefined,
        cityName: pathLabels[1] ? pathLabels[1] : undefined,
        provinName: pathLabels[0] ? pathLabels[0] : undefined,
        relaList: this.submitForm.relaList,
        projType: this.submitForm.projType,
        projMonList: this.submitForm.projMonList,
        underArea: this.submitForm.underArea
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.editData) {
            modProj(param).then(res => {
              if (res.code === 200) {
                if (this.submitForm.projId != '') {
                  this.$message.success('修改成功！')
                } else {
                  this.$message.success('添加成功！')
                }
                this.visibleDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            addProj(param).then(res => {
              if (res.code === 200) {
                if (this.submitForm.projId != '') {
                  this.$message.success('修改成功！')
                } else {
                  this.$message.success('添加成功！')
                }
                this.visibleDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },

    handlerOpen () {
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      this.submitForm = {
        projName: '',
        projNum: '',
        coveArea: '',
        aboveArea: '',
        address: '',
        builFirm: '',
        complDat: '',
        consLiceNum: '',
        imgURL: '',
        initDat: '',
        lastConsFirm: '',
        lastLinkName: '',
        lastLinkPhone: '',
        opertime: '',
        projId: '',
        on: true,
        price: 0,
        regiCountrId: '',
        regiCityId: '',
        regiProvinId: '',
        countrName: '',
        cityName: '',
        provinName: '',
        projType: '',
        relaType: '',
        mortar: '',
        underArea: '',
        relaList: [
        ],
        projMonList: [
        ]
      }
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.refreshProjCallback()
  }
}
</script>
<style lang="scss" scoped>
.show_imgUrl {
  max-width: 95px;
  max-height: 60px;
}
</style>
